<template>
  <div
    id="div-with-loading"
    class="vs-con-loading__container mx-12 mt-8"
    style="height: inherit"
  >
  <b-row class="my-2">
      <b-button
        to="/corporate-directory"
        icon-pack="feather"
        icon="icon-chevron-left"
        variant="warning"
        class="shadow-lg ml-2"
      >View All Company</b-button>
    </b-row>
    <b-overlay
      :show="isLoading"
      rounded="sm"
    >
      <sideview
        :is-sidebar-active="sidebarActivated"
        :data="billing_info"
        @closeSidebar="toggleDataSidebar"
      />

      
      <div
        class="row mx-auto"
      >
        <b-card class="w-100">
         
          <div class="p-1">
            <div class="mb-1">
              <div class="d-flex flex-wrap">
                <b-col md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
                  <img
                          ref="profileImage"
                          src="https://i.imgur.com/CJ28yzb.png"
                          style="height: 200px;"
                          class="rounded w-64 m1-2 mt-1"
                        >
                        
                  <h6 class="mt-1">
                    &nbsp;
                  </h6>
                  <h2 class="mt-1">
                    Company Name
                    <br>
                    <br>
                    
                    <a href="https://www.headhuntershq.com/" target="_blank">
                      <feather-icon
                        icon="GlobeIcon"
                        size="22"/> &nbsp;
                    </a>
                    
                    <a href="tel:+6599112233">
                      <feather-icon
                        icon="PhoneCallIcon"
                        size="22"/> &nbsp;
                    </a>

                    <a href="#" @click="toggleEmailCompose()">
                      <feather-icon
                        icon="MailIcon"
                        size="22"/> &nbsp;
                    </a>
                         
                  </h2>
                 
                 
                </b-col>

                

                <b-col md="6"  class="d-flex align-items-center justify-content-end">
                  <h6 class="mt-1">
                     &nbsp;
                  </h6>
                  <h2 class="mt-1" style="color:lightgreen">
                    Actively Hiring (170 Jobs)
                  </h2>
                </b-col>
              </div>
            </div>
          </div>
        </b-card>
      </div>

      <div id="tab-card">
        <b-row>
          <b-col md="12">
            <b-card >
              <b-tabs nav-class="mb-3 tab-action-btn-fill-container" >
                <b-tab
                  title="About"
                  
                >
            
                    <h3 class="font-weight-bolder mr-3 primary-color">Updates</h3> 
                    <b-card class="border-primary">
                        <b-card-header class="d-flex justify-content-between  pt-75 pb-25">
                          <b-card-title>Special title treatment</b-card-title>
                          <small class="text-muted w-100">July 22, 2021</small>
                        </b-card-header>
                        
                        <b-card-body>
                          <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Aliquid dolor quaerat delectus, ipsam ab tempore voluptate atque voluptatum. Nobis laboriosam repudiandae veniam dolorum adipisci. Quod libero aliquid hic ducimus nisi?</p>
                        </b-card-body>
                      </b-card>
                

                    <b-card>
                      <b-card-title class="mb-1 font-weight-bolder primary-color ">Company Former Name</b-card-title>
                      <b-card-text class="mb-2">Lorem Ipsum</b-card-text>
                      <b-card-title class="mb-1 font-weight-bolder primary-color ">HQ Location & all others location</b-card-title>
                      <b-card-text class="mb-2">Lorem Ipsum!</b-card-text>
                      <b-card-title class="mb-1 font-weight-bolder primary-color ">Company Description</b-card-title>
                      <b-card-text class="mb-2">Lorem Ipsum!</b-card-text>
                      <b-card-title class="mb-1 font-weight-bolder primary-color ">Registration No</b-card-title>
                      <b-card-text class="mb-2">Lorem Ipsum!</b-card-text>
                      <b-card-title class="mb-1 font-weight-bolder primary-color ">Incorporation date</b-card-title>
                      <b-card-text class="mb-2">Lorem Ipsum!</b-card-text>
                      <b-card-title class="mb-1 font-weight-bolder primary-color ">Websites</b-card-title>
                      <b-card-text class="mb-2">Lorem Ipsum!</b-card-text>
                      <b-card-title class="mb-1 font-weight-bolder primary-color ">Share Capital</b-card-title>
                      <b-card-text class="mb-2">Lorem Ipsum!</b-card-text>
                      <b-card-title class="mb-1 font-weight-bolder primary-color ">Contact Number</b-card-title>
                      <b-card-text class="mb-2">Lorem Ipsum!</b-card-text>
                      <b-card-title class="mb-1 font-weight-bolder primary-color ">Contact Email</b-card-title>
                      <b-card-text class="mb-2">Lorem Ipsum!</b-card-text>
                      <b-card-title class="mb-1 font-weight-bolder primary-color ">Operating status</b-card-title>
                      <b-card-text class="mb-2">Lorem Ipsum!</b-card-text>
                      <b-card-title class="mb-1 font-weight-bolder primary-color ">Company size</b-card-title>
                      <b-card-text class="mb-2">Lorem Ipsum!</b-card-text>
                      <b-card-title class="mb-1 font-weight-bolder primary-color ">Entity Type</b-card-title>
                      <b-card-text class="mb-2">Lorem Ipsum!</b-card-text>
                      <b-card-title class="mb-1 font-weight-bolder primary-color ">Primary SSIC Code</b-card-title>
                      <b-card-text class="mb-2">Lorem Ipsum!</b-card-text>
                      <b-card-title class="mb-1 font-weight-bolder primary-color ">Primary Industry Classification</b-card-title>
                      <b-card-text class="mb-2">Lorem Ipsum!</b-card-text>
                      <b-card-title class="mb-1 font-weight-bolder primary-color ">User Describid Activities</b-card-title>
                      <b-card-text class="mb-2">Lorem Ipsum!</b-card-text>
                      <b-card-title class="mb-1 font-weight-bolder primary-color ">Secondary SSIC Code</b-card-title>
                      <b-card-text class="mb-2">Lorem Ipsum!</b-card-text>
                      <b-card-title class="mb-1 font-weight-bolder primary-color ">Secondary Industry Classification</b-card-title>
                      <b-card-text class="mb-2">Lorem Ipsum!</b-card-text>
                      <b-card-title class="mb-1 font-weight-bolder primary-color ">User Describid Activities</b-card-title>
                      <b-card-text class="mb-2">Lorem Ipsum!</b-card-text>
                      <b-card-title class="mb-1 font-weight-bolder primary-color ">Statutary Recruiting Requirements</b-card-title>
                      <b-card-text class="mb-2">Lorem Ipsum!</b-card-text>
                      <b-card-title class="mb-1 font-weight-bolder primary-color ">IPO Falling</b-card-title>
                      <b-card-text class="mb-2">Lorem Ipsum!</b-card-text>
                    </b-card>

                </b-tab>
                <b-tab title="Legal" active>
                  <b-card class="border-primary">
                    <b-card-header>
                      <b-card-title>Legal</b-card-title>
                    </b-card-header>

                    <!-- chart -->
                    <b-card-body>
                      <!-- <embed type="application/pdf" src= "https://www.uncfsu.edu/assets/Documents/College%20of%20Business%20and%20Economics/legal.pdf#toolbar=0" 
                      class="card-img-top text-center"
                      style="width:70%;"
                      height="1200"
                      toolbar=0/> -->
                      "As of 12 August 2022, the company is not involved in any litigation based on the public records of the Supreme Court of Singapore. This information is provided to the best of our research ability and we make no warranties over any data inaccuracies or omissions."
                    </b-card-body>
                  </b-card>
                    
                </b-tab>
                <b-tab title="Recruitment" active>
                  <b-card class="border-primary">
                    <b-card-header>
                      <b-card-title>Data</b-card-title>
                    </b-card-header>

                    <!-- chart -->
                    <b-card-body>
                      <chartjs-component-line-chart
                        :height="450"
                        :data="chartjsData.lineAreaChart.data"
                        :options="chartjsData.lineAreaChart.options"
                      />
                    </b-card-body>
                  </b-card>
									<!-- <ag-grid-vue
                    ref="agGridTable"
                    :row-data="orders"
                    :context="context"
                    :components="components"
                    :grid-options="gridOptions"
                    style="width: 100%;"
                    class="ag-theme-material"
                    :column-defs="columnDefs"
                    :default-col-def="defaultColDef"
                    row-selection="multiple"
                    col-resize-default="shift"
                    @grid-ready="onGridReady"
                  /> -->
                  <!-- <ag-grid-no-data v-else :grid-options="gridOptions" :column-defs="columnDefs" /> -->

                  <b-pagination
                    v-model="pagination.currentPage"
                    class="my-1 float-right mr-2"
                    :total-rows="pagination.totalRows"
                    :per-page="pagination.perPage"
                    first-number
                    last-number
                    prev-class="prev-item"
                    next-class="next-item"
                  >
                    <template #prev-text>
                      <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                      />
                    </template>
                    <template #next-text>
                      <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                      />
                    </template>
                  </b-pagination>
                  
                </b-tab>
                <b-tab title="Team" active
                >
                <b-row>
                  <b-col md="6">
                  <b-card class="border-primary">
                  
                  <b-card-header class="d-flex justify-content-between ">
                    <b-card-title>Total Employee  </b-card-title>
                   
                  </b-card-header>
                  
                  <b-card-body>
                    <hr>
                    <p>70 Officers</p>
                    <p>80 Employee</p>
                  </b-card-body>
                </b-card>
                </b-col>
                <b-col md="6">
                  <b-card class="border-primary">
                  
                  <b-card-header class="d-flex justify-content-between ">
                    <b-card-title>Employee Information</b-card-title>
                  </b-card-header>
                  
                  <b-card-body>
                     <hr>
                     <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p> 
                     <p>Ex, odio asperiores. Impedit ducimus Lorem ipsum dolor sit amet consectetur adipisicing elit. Officiis labore excepturi quasi! </p>
                  </b-card-body>
                </b-card>
                </b-col>
                </b-row>
                <b-img
                  fluid
                  class="card-img-top text-center"
                  style="width:70%"
                  src="https://wcs.smartdraw.com/organizational-chart/img/photo-org.png?bn=15100111857"
                />

                </b-tab>

                <b-tab title="Financial" active
                >
                 <b-row>
                  <b-col md="6">
                  <b-card class="border-primary">
                  
                  <b-card-header class="d-flex justify-content-between ">
                    <b-card-title>Market Prescence Score</b-card-title>
                   
                  </b-card-header>
                  
                  <b-card-body>
                    <chartjs-component-doughnut-chart
                    :height="275"
                    :data="chartjsData.doughnutChart.data"
                    :options="chartjsData.doughnutChart.options"
                    class="mb-3"
                  />
                  </b-card-body>
                </b-card>
                </b-col>
                <b-col md="6">
                  <b-card class="border-primary">
                  
                  <b-card-header class="d-flex justify-content-between ">
                    <b-card-title>Corporate Spending</b-card-title>
                  </b-card-header>
                  
                  <b-card-body>
                    <chartjs-component-doughnut-chart
                    :height="275"
                    :data="chartjsData.doughnutChart.data"
                    :options="chartjsData.doughnutChart.options"
                    class="mb-3"
                  />
                  </b-card-body>
                </b-card>
                </b-col>
                </b-row>
                <b-card class="border-primary">
                  
                  <b-card-header class="d-flex justify-content-between ">
                    <b-card-title>Financial Statement</b-card-title>
                  </b-card-header>
                  
                  <b-card-body>
                     <hr>
                     Lorem ipsum, dolor sit amet consectetur adipisicing elit. Modi perferendis, 
                     nesciunt nisi, exercitationem dolore hic quam veritatis porro possimus nam 
                     excepturi consequatur maxime deserunt minima eveniet enim, maiores suscipit culpa! Lorem ipsum, dolor sit amet consectetur adipisicing elit. Magnam exercitationem totam laboriosam ut quo hic voluptate. Esse cumque nam, eius quibusdam impedit doloribus dolorum distinctio quae quo. Nemo, consequuntur quaerat.
                  </b-card-body>
                </b-card>
                
                  
                </b-tab>

                <b-tab title="Timeline" active
                >
                <b-card class="border-primary">
                  
                  <b-card-header class="d-flex justify-content-between ">
                    <b-card-title>Logs</b-card-title>
                  </b-card-header>
                  
                  <b-card-body>
                     <hr>
                     <timeline-basic />
                  </b-card-body>
                </b-card>
                
                  
                </b-tab>
                <b-tab title="Notes" active
                >
                <b-card class="border-primary">
                  
                  <b-card-header class="d-flex justify-content-between ">
                    <b-card-title><h3>Notes</h3></b-card-title>
                  </b-card-header>
                  
                  <b-card-body>
                    <small>12 October 2022 - Administrator</small>
                     <hr>
                     Lorem ipsum dolor sit amet consectetur adipisicing elit. Eius perspiciatis consequuntur asperiores ut recusandae quibusdam sed aliquam quod ducimus, quia provident ad enim maxime odit excepturi sunt qui soluta nam.
                     Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eligendi eius voluptatem magnam aliquam dolorem autem quasi ipsa voluptates culpa repellendus dolorum, at, quo tempora in facilis, molestias nesciunt. Consequatur, quod! 
                  </b-card-body>
                </b-card>
                
                  
                </b-tab>

              </b-tabs>
            </b-card>
          </b-col>
          </b-row>
          
      </div>
          <!-- <div v-for="(group, i) in eventsGroups" :key="i"> -->
          <!-- .slice(i * 4, (i + 1) * 4) -->
          

            <!-- AgGrid Table -->
            
    </b-overlay>
    <email-compose v-model="shallShowEmailComposeModal" />
  </div>
</template>

<script>
import { format } from 'date-fns'

import { StripeCheckout } from '@vue-stripe/vue-stripe'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  BCard, BCardBody, BCardHeader, BCardTitle,
  BFormGroup,
  BFormInput,
  BButton,
  BRow,
  BCol,
  VBTooltip,
  BOverlay,
  BFormCheckbox,
  BAlert,
  BTab,
  BCardText,
  BTabs,
} from 'bootstrap-vue'
import { debounce } from 'lodash'
import Sideview from '../payments/Sideview.vue'
import flatPickr from 'vue-flatpickr-component'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import ChartjsComponentLineChart from './components/ChartjsComponentLineChart.vue'
import ChartjsComponentDoughnutChart from './components/ChartjsComponentDoughnutChart.vue'
import TimelineBasic from './components/TimelineBasic.vue'
import chartjsData from './components/chartjsData'
import EmailCompose from '../email/EmailCompose.vue'



export default {
  components: {
    ChartjsComponentLineChart,
    ChartjsComponentDoughnutChart,
    TimelineBasic,
    AppTimeline,
    AppTimelineItem,
    ToastificationContent,
    StripeCheckout,
    Sideview,
    vSelect,
    BCard,
    BTab,
    BCardText,
    BTabs,
    BButton,
    BRow,
    BCol,
    BOverlay,
    BFormCheckbox,
    BAlert,
    BCardBody, BCardHeader, BCardTitle, flatPickr,
    EmailCompose
  },
  data() {
    return {
      purchase_category: '',
      secondary_code: '',
      promo_code: '',
      promo_popup: false,
      customer_email: '',
      sidebarActivated: false,
      proceed_transaction: false,
      billing_info: {},
      publishableKey: process.env.VUE_APP_STRIPE_KEY,
      corporateItems: [
        {
          price: 'price_1Jhi4jI2ri5g5XG9rpt34CoH',
          //  price: "price_1Ji2JbI2ri5g5XG9O7TH1lMU",
          quantity: 1,
        },
      ],
      successURL: 'https://dashboard.headhuntershq.com/payment-success',
      cancelURL: 'https://dashboard.headhuntershq.com/corporate-directory',
      blur_check: 'blur',
      job_link: '',
      not_fullname: '',
      not_email: '',
      not_position: '',
      not_industry: '',
      shallShowEmailComposeModal : false,
      event_id: '',
      fullname: '',
      email: '',
      resume_uploaded: false,
      show_inside: false,
      popupActive: false,
      image_url: 'default',
      event_exist: false,
      action: 'insert',
      current_item: {},
      popupReg: false,
      item_id: '',
      job: {},
      companies: [],
      registered: [],
      events_data: [],
      select_tags: [],
      select_name: '',
      all_positions: [],
      selected_status: 0,
      selected_industry: [],
      selected_company: '',
      current_user: '',
      position: '',
      description: '',
      job_count: 0,
      default_count: 50,
      recruitment_statuses: [
        { text: 'All recruitment status', value: 0 },
        { text: '❌Inactive', value: 1 },
        { text: '✔️Active', value: 2 },
      ],
      industries: [
        {
          text: 'Accommodation and food service activities',
          value: 'Accommodation and food service activities',
        },
        {
          text: 'Administrative and support service activities',
          value: 'Administrative and support service activities',
        },
        { text: 'Agriculture and fishing', value: 'Agriculture and fishing' },
        {
          text: 'Arts, entertainment and recreation',
          value: 'Arts, entertainment and recreation',
        },
        { text: 'Construction', value: 'Construction' },
        { text: 'Education', value: 'Education' },
        {
          text: 'Electricity,gas,steam and air-conditioning supply',
          value: 'Electricity,gas,steam and air-conditioning supply',
        },
        {
          text: 'Financial and insurance activities',
          value: 'Financial and insurance activities',
        },
        {
          text: 'Health and social services',
          value: 'Health and social services',
        },
        {
          text: 'Information and communications',
          value: 'Information and communications',
        },
        { text: 'Manufacturing', value: 'Manufacturing' },
        { text: 'Mining and quarrying', value: 'Mining and quarrying' },
        {
          text: 'Other service activities',
          value: 'Other service activities',
        },
        {
          text: 'Professional, scientific and technical activities',
          value: 'Professional, scientific and technical activities',
        },
        {
          text: 'Public administration and defence',
          value: 'Public administration and defence',
        },
        { text: 'Real estate activities', value: 'Real estate activities' },
        {
          text: 'Transportation and storage',
          value: 'Transportation and storage',
        },
        {
          text: 'Water supply;sewerage waste management and remediation activities',
          value:
            'Water supply;sewerage waste management and remediation activities',
        },
        {
          text: 'Wholesale and retail trade',
          value: 'Wholesale and retail trade',
        },
      ],
      rangePicker: ['2019-05-01', '2019-05-10'],
      chartjsData,
      dates: [],
      popup_payment: false,
      account_status: { corporate_directory: { active: false } },
      payment_id: '',
      instance: null,
      orders: [ { "Job Title" : "Engineer",
                  "Publicly Published Date" : "2019-05-01",
                  "Position" : "Sales",
                  "Email" : "JohnDoe@smiley.com",
                  "Phoneno" : "6512345"},
                  { "Job Title" : "Sales",
                  "Publicly Published Date" : "2019-05-01",
                  "Position" : "Engineer",
                  "Email" : "WayneEngineer@smiley.com",
                  "Phoneno" : "651231"},
                  { "Job Title" : "Sales",
                  "Publicly Published Date" : "2019-05-01",
                  "Position" : "Engineer",
                  "Email" : "White@smiley.com",
                  "Phoneno" : "65313131"}],

      // AgGrid
      context: null,
      gridApi: null,
      gridOptions: {
        suppressHorizontalScroll: true,
      },
      defaultColDef: {
        sortable: true,
        resizable: true,
        suppressMenu: true,
        floatingFilter: true,
        floatingFilterComponentParams: { suppressFilterButton: true }, // make right-hand button disappear,
      },
      columnDefs: [
        {
          headerName: 'Job Title',
          field: 'Job Title',
          filter: true,
          sortable: true,
          width: 260,
        },
        {
          headerName: 'Published Salary',
          field: 'Published Salary',
          filter: true,
          sortable: true,
          width: 260,
        },
        {
          headerName: 'Published Date',
          field: 'Published Date',
          filter: true,
          sortable: true,
          width: 260,
        },
        {
          headerName: 'My Matched Candidate(s)',
          field: 'My Matched Candidate(s)',
          filter: true,
          sortable: true,
          width: 260,
        },
      ],
      components: [],

      // server pagination
      previousFilters: {},
      filters: {},
      sorters: [],
      columns: [],
      pagination: {
        currentPage: 1,
        totalRows: 1,
        perPage: 10,
      },

      exportingCSV: false,
    }
  },
  computed: {
    eventsGroups() {
      return Array.from(Array(Math.ceil(this.events_data.length / 4)).keys())
    },
  },
  watch: {
    selected_status: {
      handler(val, oldVal) {
        this.filterAgencies()
      },
    },
    selected_industry: {
      handler(val, oldVal) {
        this.filterAgencies()
        // this.filter_events();
      },
    },
    selected_company: {
      handler(val, oldVal) {
        this.filterAgencies()
        // this.filter_events();
      },
    },
    deep: true,
    
  },

  mounted() {
    this.gridApi = this.gridOptions.api
    try {
      const next = this.$children[0].$refs.btnclose
      next.$el.addEventListener('click', this.onClickCloseButton, false)
    } catch (error) {}
  },

  created() {
    // const { token } = this.$store.state.auth.ActiveUser || {}
    this.email_address = this.$store.state.auth.ActiveUser.email
    // this.$http.defaults.headers.common["Authorization"] = `Token ${token}`;
    try {
      this.resume_uploaded = this.$store.state.auth.ActiveUser.resume_upload
    } catch (error) {}

    this.getDirectory()
  },
  methods: {
    checkDescription(description) {
      return description ? `(${description})` : ''
    },
    validatePromo() {
      if (this.promo_code.length > 2) {
        this.$http
          .get(`/api/promo-code/${this.promo_code}/${this.secondary_code}`)
          .then(response => {
            if (response.data.success) {
              this.promo_popup = false
              this.promo_code = ''
              this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Promo Code Alert',
                    icon: 'CheckCircleIcon',
                    text: 'Your subscription has been activated successfully',
                    variant: 'success',
                  },
                },
                { timeout: this.$longestTimeout },
              )

              window.location.reload()
            } else {
              this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Promo Code Alert',
                    icon: 'AlertCircleIcon',
                    text: 'The promo code you have entered is either invalid or has already expired.',
                    variant: 'danger',
                  },
                },
                { timeout: this.$longestTimeout },
              )
            }
          })
          .catch(error => {})
      } else {
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: 'Promo Code Notification',
              icon: 'AlertCircleIcon',
              text: 'A valid promo code is required before validation.',
              variant: 'danger',
            },
          },
          { timeout: this.$longestTimeout },
        )
      }
    },
    skipPromo() {
      const section = this.purchase_category
      this.promo_popup = false

      this.$refs.checkoutRef.redirectToCheckout()
    },
    toggleDataSidebar() {
      this.sidebarActivated = false
    },
    toggleEmailCompose() {
      this.shallShowEmailComposeModal = !this.shallShowEmailComposeModal;
      if(this.shallShowEmailComposeModal){
      } else {
      }
    },
    getBillingInfo() {
      this.$http
        .get('/api/billing-info')
        .then(response => {
          if (response.data.success) {
            const results = response.data.output
            this.billing_info = results
            const neededKeys = [
              'phone_number',
              'email',
              'address_1',
              'first_name',
              'last_name',
              'company_name',
            ]

            if (
              neededKeys.every(key => Object.keys(this.billing_info).includes(key))
            ) {
              this.proceed_transaction = true
            } else {
              this.proceed_transaction = false
            }
          }
        })
        .catch(error => {})
    },
    subscribe(section) {
      this.getBillingInfo()
      this.purchase_category = section
      if (section === 'corporate_directory') {
        this.secondary_code = 300
        this.successURL = `https://dashboard.headhuntershq.com/payment-success/${this.payment_id}/300`
      }
      if (this.proceed_transaction) {
        this.promo_popup = true
      } else {
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: 'Billing Notification',
              icon: 'AlertCircleIcon',
              text: 'Please update your billing information before subscribing',
              variant: 'danger',
            },
          },
          { timeout: this.$longestTimeout },
        )

        this.sidebarActivated = true
      }
    },
    generate_payment_id() {
      this.$http
        .get('/api/payment-id/300')
        .then(response => {
          if (response.data.success) {
            this.payment_id = response.data.payment_id
          }
        })
        .catch(error => {})
    },

    handleCompanyInput: debounce(function (text) {
      if (text.length === 0) {
        this.selected_company = ''
        this.filterAgencies()
      }
      this.getEmployers(text)
    }, 1000),

    // handleCompanyInput:debounce(() => {
    //
    //   }, 1000),

    handleIndustryInput(text) {
      return text
    },
    getEmployers(query) {
      this.companies = []

      this.$http
        .get(`/api/company-search/${query}`)
        .then(response => {
          if (response.data.success) {
            const records = response.data.data
            records.map(item => {
              if (!this.companies.includes(item.text)) {
                this.companies.push(item.text)
              }
            })
          }
        })
        .catch(error => {})
    },
    check_empty(value) {
      if (value) {
        if (value.length > 4) {
          return true
        }
        return false
      }
      return true
    },
    send_response() {
      if (
        this.check_empty(this.not_fullname)
        && this.check_empty(this.not_email)
        && this.check_empty(this.not_position)
      ) {
        const all_data = {
          fullname: this.not_fullname,
          email: this.not_email,
          position: this.not_position,
          industry: this.not_industry,
        }
        this.$http
          .post('/api/response', { data: all_data })
          .then(response => {
            const { success } = response.data
            if (success) {
              this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Response Sent',
                    icon: 'AlertCircleIcon',
                    text: response.data.message,
                    variant: 'success',
                  },
                },
                { timeout: this.$longestTimeout },
              )

              this.not_position = ''
              this.not_fullname = ''
              this.not_email = ''
              this.not_industry = ''
            } else {
              this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Response Failed',
                    icon: 'AlertCircleIcon',
                    text: response.data.message,
                    variant: 'danger',
                  },
                },
                { timeout: this.$longestTimeout },
              )
            }
          })
          .catch(error => {})
      } else {
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: 'Response Failed',
              icon: 'AlertCircleIcon',
              text: 'Please fill all form values correctly.',
              variant: 'danger',
            },
          },
          { timeout: this.$longestTimeout },
        )
      }
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    },
    joinEvent(item, event) {
      event.target.innerText = 'Applied'
      const { email } = this.$store.state.auth.ActiveUser
      const { fullname } = this.$store.state.auth.ActiveUser

      const all_data = {
        job_id: item._id,
        email,
        fullname,
        approved: true,
      }

      if (!email) {
        this.event_id = item._id
        this.popupReg = true
      } else {
        this.$http
          .post('/api/apply', { data: all_data })
          .then(response => {
            const { success } = response.data
            if (success) {
              this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Application Alert',
                    icon: 'AlertCircleIcon',
                    text: response.data.message,
                    variant: 'success',
                  },
                },
                { timeout: this.$longestTimeout },
              )
              this.popupReg = false
            } else {
              this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Application Alert',
                    icon: 'AlertCircleIcon',
                    text: response.data.message,
                    variant: 'success',
                  },
                },
                { timeout: this.$longestTimeout },
              )
            }
          })
          .catch(error => {})
      }
    },

    viewMore() {
      this.default_count *= 2
      this.getDirectory()
    },

    filterAgencies() {
      this.getDirectory()
    },
    trimMyString(string) {
      const start = 0
      const maxLength = 200
      // Note - `start` is if I want to start after some point of the string
      if (string.length > maxLength) {
        const trimmedString = string.substr(start, maxLength)
        return `${trimmedString.substr(
          start,
          Math.min(trimmedString.length, trimmedString.lastIndexOf(' ')),
        )} ......`
      }
      return string
    },
    filter_events(data) {
      // let selected_tags = [];
      // for (let data of this.categories) {
      //   for (let categ of this.select_tags) {
      //     if (data.value === categ) {
      //       selected_tags.push(data.text);
      //     }
      //   }
      // }

      // let selected_companies = [];
      // for (let data of this.companies) {
      //   for (let categ of this.select_company) {
      //     if (data.value === categ) {
      //       selected_companies.push(data.text);
      //     }
      //   }
      // }

      let new_events = false
      if (data.ea_name.includes(this.select_name)) {
        selected_date = data.text
        new_events = true
      }

      // let new_events = false;

      // let comp =
      //   selected_companies.length > 0
      //     ? selected_companies.includes(data.company)
      //     : true;
      // let tags =
      //   selected_tags.length > 0
      //     ? selected_tags.some((v) => data.tags.includes(v))
      //     : true;
      // let dates = data.position
      //   .toLowerCase()
      //   .includes(this.select_position.toLowerCase());

      // if (comp && tags && dates) {
      //   new_events = true;
      // }

      return new_events
      // this.events_data = new_events;
    },

    selectOne(jobid, all_data) {
      let active_job = {}
      for (const item of all_data) {
        if (item._id === jobid) {
          active_job = item
        }
      }
      if (Object.keys(active_job).length !== 0) {
        this.viewMore(active_job)
      } else {
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: 'Job Alert',
              icon: 'AlertCircleIcon',
              text: "It seems this job position has been filled or doesn't exists.",
              variant: 'danger',
            },
          },
          { timeout: this.$longestTimeout },
        )
      }
    },

    editEvent(item) {
      this.action = 'update'
      this.item_id = item._id
      this.position = item.position
      this.description = item.description
      //   this.select_date = item.date;
      //   this.select_tags = item.tags;
      this.image_url = item.image_url
      this.popupActive = true
    },

    getDirectory() {
      this.isLoading = false
    },
    extract_values() {
      const selected_tags = []
      for (const data of this.categories) {
        for (const categ of this.select_tags) {
          if (data.value === categ) {
            selected_tags.push(data.text)
          }
        }
      }
      let selected_date = ''
      for (const data of this.dates) {
        if (data.value === this.select_date) {
          selected_date = data.text
        }
      }

      const all_data = {
        position: this.position,
        description: this.description,
        date: selected_date,
        tags: selected_tags,
        image_url: this.image_url,
      }
      return all_data
    },

    onClickCloseButton() {
      this.$emit('closePopup')
    },

    updateFiles(input) {
      if (this.email) {
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: 'Resume Upload',
              icon: 'AlertCircleIcon',
              text: 'Resume upload in progress.',
              variant: 'warning',
            },
          },
          { timeout: this.$longestTimeout },
        )
        if (input.target.files && input.target.files[0]) {
          const reader = new FileReader()
          reader.onload = e => {}
          const fd = new FormData()
          fd.append('file', input.target.files[0])
          delete this.$http.defaults.headers.common.Authorization
          this.$http
            .post(`/api/upload-resume/${this.email}`, fd)
            .then(response => {
              this.resume_uploaded = true
              // let image_url = response.data.data.display_url;
              this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Resume Upload',
                    icon: 'AlertCircleIcon',
                    text: response.data.message,
                    variant: 'success',
                  },
                },
                { timeout: this.$longestTimeout },
              )
            })
            .catch(error => {
              this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Resume Upload',
                    icon: 'AlertCircleIcon',
                    text: 'Resume upload failed. Try again later.',
                    variant: 'warning',
                  },
                },
                { timeout: this.$longestTimeout },
              )
            })
          reader.readAsDataURL(input.target.files[0])
        }
      } else {
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: 'Resume Upload',
              icon: 'AlertCircleIcon',
              text: 'Please fill the form above to proceed.',
              variant: 'danger',
            },
          },
          { timeout: this.$longestTimeout },
        )
      }
    },
    onCellChange(event) {},
    onSortChanged(event) {
      this.sorters = this.gridApi.getSortModel()

      this.get_company_users()
        .then(() => {
          this.adjustAgGridSize()
        })
    },
    onFilterChanged(event) {
      this.filters = this.gridApi.getFilterModel()

      setTimeout(() => {
        if (JSON.stringify(this.filters) !== JSON.stringify(this.previousFilters)) {
          this.get_company_users()
            .then(() => {
              this.adjustAgGridSize()

              this.previousFilters = this.filters

              setTimeout(() => {
                this.gridApi.setFilterModel(this.filters) // infinite loop
              }, 100)
            })

        }
      }, 1000)
    },
    onGridReady(event) {
      this.gridApi = this.gridOptions.api

      this.adjustAgGridSize()
    },
    adjustAgGridSize() {
      this.gridOptions.api.setDomLayout('autoHeight')
      this.$refs.agGridTable.$el.style.height = ''

      const gridVh = this.$getGridVh()
      const gridHeight = this.$refs.agGridTable.$el.clientHeight
      if (gridHeight > this.$vh(gridVh)) {
        this.gridOptions.api.setDomLayout('normal')
        this.$refs.agGridTable.$el.style.height = `${gridVh}vh`
      }
      this.$refs.agGridTable.$el.style.width = `99.9%`

      this.gridApi.sizeColumnsToFit()
    },
  },
}
</script>
<style lang="scss">
.blur {
  filter: blur(16px);
}
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';


@import "~@core/scss/base/pages/app-email.scss";
</style>